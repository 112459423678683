<template>
  <!--创建地图容器元素-->
  <div>
    <van-nav-bar fixed left-arrow @click-left="$router.push('/ruralFilm')">
      <template #right>
        <van-button type="info" round style="width: 8vw" size="mini">
          <template #icon>
            <van-icon color="#fff" name="search" />
          </template>
        </van-button>
      </template>
      <template #title>
        <input
          type="text"
          class="searchInput"
          @focus="$router.push('/ruralFilm/searchPoint')"
          placeholder="搜寻更多观影点"
        />
      </template>
    </van-nav-bar>
    <div id="tdtMapDivID" ref="tdtMapDivID" class="divTdtMap"></div>
    <div class="location">
      <!-- 当前放映点位信息 -->
      <div>
        <span>您当前所在位置：</span>
        <span>
          <van-icon name="location" color="#1492FF" />
          <span>{{ userPoint.name }}</span>
        </span>
      </div>
      <div>
        <!--        更改放映点-->
        <a style="color: #1492ff" @click="toSelectPoint"> 更改放映点 </a>
      </div>
    </div>
    <!-- 放映点列表 -->
    <div class="cinemaList">
      <div class="cinemaTitle">推荐观影点</div>
      <div class="cinemaItem box" v-for="i in mapData.surrounds" :key="i.id">
        <div class="cinemaItem-header">
          <div>距离{{ (i.meter / 1000).toFixed(2) }}公里</div>
          <div @click="toPointDetail(i)">
            查看详情
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="cinemaItem-body">
          <!-- 点名 -->
          <div class="cinemaItem-name">
            {{ i.name }}
          </div>
          <!-- 地址 -->
          <div class="cinemaItem-address">地址：{{ i.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '@/api'

export default {
  name: 'TdtMap',
  data() {
    return {
      userPoint: {},
      // 中心点
      centerPoint: {
        lng: 119.9201,
        lat: 31.068228,
        name: '后洋村',
      },
      mapData: {
        regionArea: {
          lng: 119.9201,
          name: '后洋村',
          id: 100408,
          lat: 31.068228,
        },
        lng: 119.9201,
        surrounds: [],
        lat: 31.068228,
      },
    }
  },
  created() {},
  mounted() {
    // 获取用户当前放映点
    this.userPoint = this.$store.state.userPoint || null
    if (!this.userPoint) {
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: {
          from: 'pointMap',
          back: true,
        },
      })
    }
    // 初始化天地图
    this.getSurrounds().then((res) => {
      console.log(res)
      const { success, result } = res
      if (success) {
        const { surrounds } = result
        this.mapData = {
          ...this.result,
          // 去掉第一个放映点
          surrounds: surrounds.slice(1),
        }
        console.log({ surrounds })
        this.centerPoint = this.userPoint
        this.initTdtMap()
      }
    })
  },
  watch: {},
  methods: {
    // 初始化天地图
    initTdtMap() {
      let T = window.T
      // 1.创建天地图主界面
      const tdtMapDivID = this.$refs.tdtMapDivID
      console.log(tdtMapDivID)

      this.tdtMap = new T.Map(this.$refs.tdtMapDivID)
      //设置显示地图的中心点和级别
      this.tdtMap.centerAndZoom(
        new T.LngLat(this.centerPoint.lng, this.centerPoint.lat),
        13.5,
      )
      const centerPoint = new T.LngLat(
        this.centerPoint.lng,
        this.centerPoint.lat,
      )
      const icon = new T.Icon({
        iconUrl: 'https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d24c2c2cd.png',
        iconSize: new T.Point(30, 30),
        iconAnchor: new T.Point(10, 25),
      })
      const marker = new T.Marker(centerPoint, { icon: icon })
      const label = new T.Label({
        text: this.centerPoint.name,
        position: centerPoint,
        zindex: 1,
        offset: new T.Point(-30, -40),
      })
      this.tdtMap.addOverLay(label)

      this.tdtMap.addOverLay(marker)
      const circle = new T.Circle(
        new T.LngLat(this.centerPoint.lng, this.centerPoint.lat),
        1500,
      )
      this.tdtMap.addOverLay(circle)
      // 5.创建地图类型控件

      // 6.将控件添加到地图，一个控件实例只能向地图中添加一次。
      // this.tdtMap.addControl(ctrl);
      // 7.创建坐标，通常是调取接口获得经纬度
      // const point = new T.LngLat(112.9388, 28.2280);
      // 8.创建覆盖使用的图标
      // const icon = new T.Icon({
      //   iconUrl: '../marker-red.png',
      //   iconSize: new T.Point(27, 27),
      //   iconAnchor: new T.Point(10, 25)
      // });

      for (let i = 0; i < this.mapData.surrounds.length; i++) {
        const point = new T.LngLat(
          this.mapData.surrounds[i].lng,
          this.mapData.surrounds[i].lat,
        )
        const marker = new T.Marker(point)
        this.tdtMap.addOverLay(marker)

        const label = new T.Label({
          text: this.mapData.surrounds[i].name,
          position: point,
          offset: new T.Point(-45, -60),
          data: null,
        })
        label.addEventListener('click', (e) => {
          // this.$emit('click', this.mapData.surrounds[i])
          console.log(e)
        })
        this.tdtMap.addOverLay(label)
      }
    },

    // 获取周边放映点
    getSurrounds() {
      return api.getFilmAroundPoints({ ...this.userPoint })
    },
    // 跳转到选择放映点页面
    toSelectPoint() {
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: { form: this.$route.path, ...this.$route.query, back: true },
      })
    },
    // 跳转到放映点详情页面
    toPointDetail(point) {
      this.$router.push({
        path: '/ruralFilm/pointDetail/' + point.id,
        // query: { pointId: point.id },
      })
    },
  },
}
</script>

<style scoped lang="less">
.searchInput {
  width: 70vw;
  height: 44px;
  background: #ffffff91;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  padding: 10px 12px;
}

.divTdtMap {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 40vh;
  z-index: 0;
  //box-shadow: -1px 0px 30px #333333 inset;
}

.location {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 60px;
  border-top: 1px solid #eee;
  color: #363a44;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: @font-size-base;
    & > span:first-child {
      font-size: @font-size-large;
      font-weight: 500;
      color: #363a44;
    }
  }
}

.cinemaTitle {
  padding: 10px;
  font-size: @font-size-medium;
  color: #363a44;
  font-weight: 500;
}

.cinemaItem {
  border-bottom: 1px solid #eee;
  margin: 12px auto 0 auto;
  width: 351px;
  height: 117px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e8e9ec;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: @font-size-medium;
    font-weight: 500;
    margin: 12px;
    color: #666;

    & > div:first-child {
      color: #1492ff;
    }
  }

  &-body {
    border-top: 1px solid #eee;
    padding: 10px 0;
    font-size: @font-size-base;
    padding: 12px;
  }

  &-name {
    font-weight: 500;
    color: #333;
  }

  &-address {
    font-size: @font-size-medium;
    color: #686b73;
  }
}

.cinemaList {
  margin-top: 10px;
  padding: 0 10px;
  max-height: 50vh;
  overflow-y: auto;
}

::v-deep .van-nav-bar--fixed {
  top: 2vh;
  background-color: transparent;
}

::v-deep .van-nav-bar__title {
  max-width: 70%;
}
</style>
